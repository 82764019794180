.create-nft-area
{
    background-color: #FEFEFE;
}

.nft-option-card {
    background-color: var(--whiteColor);
    -webkit-box-shadow: 0 0 15px rgb(0 0 0 / 10%);
    box-shadow: 0 0 15px rgb(0 0 0 / 10%);
    padding: 30px 20px;
    position: relative;
    border-radius: 15px;
    margin-bottom: 30px;
    -webkit-transition: var(--transition);
    transition: var(--transition);
}

.nft-option-card:hover {
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
}

.nft-option-card .top-btn {
    position: absolute;
    top: -15px;
    right: 20px;
    padding: 10px 18px 7px;
    color: var(--whiteColor);
    background-color: var(--titleColor);
    font-size: 14px;
    font-weight: 600;
    display: inline-block;
    border-radius: 15px;
    -webkit-transition: var(--transition);
    transition: var(--transition);
}

.nft-option-card:hover .top-btn {
    background-color: var(--pinkColor);
}

.nft-option-card h3 a {
    color: var(--transition);
    -webkit-transition: var(--transition);
    transition: var(--transition);
}

.nft-option-card img {
    margin-bottom: 20px;
}

.nft-option-card .section-title p {
    min-width: 100px;
}

.image-upload-link
{
    width: 100%;
    cursor: pointer;
}
.image-upload-link > input[type='file']
{
    display: none;
}

.image-nft > .item-details-img
{
    background-color: #efefef;
    min-width: 100%;
    height: 350px;
    border: 1px dashed #aaa;
    border-radius: 30px;
}
.image-nft > .item-details-img > .bg-image
{
    position: relative;
    top: 0;
    left: 0;
}

.image-nft > .item-details-img > .upload-icon
{
    position: absolute;
    width: 20px;
}